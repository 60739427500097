import React from 'react'
import { bm, be } from '../utilities/bliss'

export type LoaderPropsType = {
  loaderType?: 'basic' | 'complex' | 'section' | 'kitty' | 'section-large' | 'button'
  visible: boolean
  style?: React.CSSProperties
  color?: string
  message?: string
}

const Loader: React.FC<LoaderPropsType> = ({ loaderType = 'basic', visible = true, color, style, message }) => {
  if (visible) {
    return (
      <div className={bm('loader', [loaderType, color || ''])} style={style}>
        <span className={be('loader', 'spinner')} />
        <span className={be('loader', 'message')}>{message}</span>
      </div>
    )
  }
  return null
}

export default Loader
