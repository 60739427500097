const locale = process.env.NEXT_PUBLIC_LOCALE || 'en'

module.exports = {
  locales: [locale],
  defaultLocale: locale,
  defaultNS: 'hedepy',
  loader: false,
  pages: {
    '*': ['hedepy'],
    // '/': ['home', 'example'],
    // '/about': ['about'],
  },
}
