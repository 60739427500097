import config from '../../i18n'
import { LOCALE } from '../constants/locale.constants'
import currentLocale from 'locales/current/hedepy.json'
import { Translate } from 'next-translate'
import transCore from './i18n.transCore'

const lang = LOCALE || 'en'

const tWithoutNs = transCore({
  lang,
  config,
  pluralRules: new Intl.PluralRules(lang),
  allNamespaces: { hedepy: currentLocale as any },
})

export const customTranslationHook = () =>
  ({
    t: (key: string, query?: any, options = {}) => {
      return tWithoutNs(key, query, { ns: 'hedepy', ...options })
    },
  } as { t: Translate })
