import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

// because a lot of statically generated content relies on this, we cannot use growthbook easily
export const isNewBrandingActive = process.env.NEXT_PUBLIC_NEW_BRANDING === 'true'

// this is a utility function from https://github.com/shadcn-ui/ui/blob/main/apps/www/lib/utils.ts
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
