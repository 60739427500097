import 'dayjs/locale/cs'
import 'dayjs/locale/it'
import 'dayjs/locale/ro'
import 'dayjs/locale/pl'
import 'dayjs/locale/sk'
import 'dayjs/locale/el'
import 'dayjs/locale/hu'
import 'dayjs/locale/uk'
import 'dayjs/locale/fi'
import 'dayjs/locale/sl'
import 'dayjs/locale/et'
import 'dayjs/locale/lt'

import dayjs from 'dayjs'
import { LOCALE } from '../constants/locale.constants'
import { customTranslationHook } from './i18n.hook'

dayjs().locale(LOCALE || 'cs') // set dayjs translation

export const getTranslate = customTranslationHook
