/**
 * Copied from https://github.com/stackscz/client-core
 */
import classNames from 'classnames'

/**
 * Low-level utility for handling dynamic prefix/modifiers.
 *
 * @param {string} prefix prefix
 * @param {string|array|object} modifiers modifiers
 * @param {string|array|object} classes classes
 * @param {boolean} bootstrapModule bootstrapModule
 * @returns {string} classes
 */
export const item = (
  prefix: string,
  modifiers: string | boolean | string[],
  classes: string | boolean,
  bootstrapModule: boolean,
): string => {
  const blissClass = [prefix]
  const normalizedModifiers = modifiers ? classNames(modifiers) : false
  const normalizedClasses = classes ? classNames(classes) : false

  if (normalizedModifiers) {
    blissClass.push(
      normalizedModifiers
        .trim()
        .split(' ')
        .map((mod) => `${prefix}${bootstrapModule ? '-' : '--'}${mod}`)
        .join(' '),
    )
  }

  if (normalizedClasses) {
    blissClass.push(normalizedClasses)
  }

  return blissClass.join(' ')
}

/**
 * High-level utility for handling Bliss module with dynamic name/modifiers.
 *
 * @param {string} moduleName moduleName
 * @param {string|array|object} modifiers modifiers
 * @param {string|array|object} classes classes
 * @param {boolean} bootstrapModule bootstrapModule
 * @returns {string} item
 */
export const blissModule = (
  moduleName: string,
  modifiers: string | string[] | boolean = false,
  classes: string | boolean = false,
  bootstrapModule: boolean = false,
): string => item(moduleName, modifiers, classes, bootstrapModule)

/**
 * High-level utility for handling Bliss element with dynamic name/modifiers.
 *
 * @param {string} moduleName moduleName
 * @param {string} elementName elementName
 * @param {string|array|object} modifiers modifiers
 * @param {string|array|object} classes classes
 * @param {boolean} bootstrapModule bootstrapModule
 * @returns {string} item
 */
export const blissElement = (
  moduleName: string,
  elementName: string,
  modifiers: string | string[] | boolean = false,
  classes: string | boolean = false,
  bootstrapModule: boolean = false,
): string => item(`${moduleName}__${elementName}`, modifiers, classes, bootstrapModule)

export const bm = blissModule
export const be = blissElement
